.datatable-demo .p-datatable-responsive .p-datatable-tbody > tr.p-datatable-row > td:last-child .p-column-title {
    display: none;
  }
  .carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-details > .p-grid {
    border-radius: 3px;
    margin: 0.3em;
    text-align: center;
    padding: 2em 0 2.25em 0;
  }
  .carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-title {
    font-weight: 700;
    font-size: 20px;
    margin-top: 24px;
  }
  .carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data .car-subtitle {
    margin: 0.25em 0 2em 0;
  }
  .carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button {
    margin-left: 0.5em;
  }
  .carousel-demo .p-carousel .p-carousel-content .p-carousel-item .car-data button:first-child {
    margin-left: 0;
  }
  .dataview-demo .p-dataview .car-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2em;
  }
  .dataview-demo .p-dataview .car-details > div {
    display: flex;
    align-items: center;
  }
  .dataview-demo .p-dataview .car-details > div img {
    margin-right: 14px;
  }
  .dataview-demo .p-dataview .car-detail {
    padding: 0 1em 1em 1em;
    margin: 1em;
  }
  @media (max-width: 1024px) {
    .dataview-demo .p-dataview .car-details img {
      width: 75px;
    }
  }
  .input-demo .p-multiselect {
    min-width: 15rem;
  }
  .input-demo .multiselect-custom .country-item {
    display: flex;
    align-items: center;
  }
  .input-demo .multiselect-custom .country-item span.flag {
    width: 18px;
    height: 12px;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .input-demo .multiselect-custom .country-item-value {
    border-radius: 3px;
    display: inline-flex;
    margin-right: 0.5rem;
    background-color: #2196F3;
    color: #ffffff;
  }
  .list-demo .product-name {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .list-demo .product-description {
    margin: 0;
  }
  .list-demo .product-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
  }
  .list-demo .product-category {
    font-weight: 600;
    vertical-align: middle;
  }
  .list-demo .product-list-item {
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
  }
  .list-demo .product-list-item img {
    /*width: 150px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
    margin-right: 2rem;
  }
  .list-demo .product-list-item .product-list-detail {
    flex: 1 1 0;
  }
  .list-demo .product-list-item .p-rating {
    margin: 0 0 0.5rem 0;
  }
  .list-demo .product-list-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }
  .list-demo .product-list-item .product-list-action {
    display: flex;
    flex-direction: column;
  }
  .list-demo .product-list-item .p-button {
    margin-bottom: 0.5rem;
  }
  .list-demo .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .list-demo .product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
  }
  .list-demo .product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }
  .list-demo .product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
  .list-demo .product-grid-item {
    margin: 0.5em;
    border: 1px solid var(--surface-d);
  }
  .list-demo .product-grid-item .product-grid-item-top, .list-demo .product-grid-item .product-grid-item-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-demo .product-grid-item img {
    /*width: 75%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
    margin: 1rem 0;
  }
  .list-demo .product-grid-item .product-grid-item-content {
    text-align: center;
  }
  .list-demo .product-grid-item .product-price {
    font-size: 1.5rem;
    font-weight: 600;
  }
  @media screen and (max-width: 576px) {
    .list-demo .product-list-item {
      flex-direction: column;
      align-items: center;
    }
    .list-demo .product-list-item img {
      width: 75%;
      margin: 2rem 0;
    }
    .list-demo .product-list-item .product-list-detail {
      text-align: center;
    }
    .list-demo .product-list-item .product-price {
      align-self: center;
    }
    .list-demo .product-list-item .product-list-action {
      display: flex;
      flex-direction: column;
    }
    .list-demo .product-list-item .product-list-action {
      margin-top: 2rem;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
  .menu-demo .stepsdemo-content {
    padding: 1em 0;
  }
  .menu-demo .stepsdemo-content p {
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    margin: 0;
  }
  .menu-demo .stepsdemo-content i {
    vertical-align: middle;
    font-size: 1.5em;
    margin: 0;
  }
  .menu-demo .contextmenu-image {
    width: 100%;
  }
  .messages-demo .p-field > label {
    width: 125px;
  }
  .messages-demo .p-inputtext {
    margin-right: 0.5rem;
  }
  .misc-demo .badges .p-badge, .misc-demo .badges .p-tag {
    margin-right: 0.5rem;
  }
  .misc-demo .badges .p-tag {
    line-height: 1;
  }
  .misc-demo .p-button {
    margin-right: 0.5rem;
  }
  .misc-demo .p-overlay-badge {
    margin-right: 2rem;
  }
  .overlay-demo p {
    line-height: 1.5;
    margin: 0;
  }
  .overlay-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .panel-demo .p-toolbar {
    flex-wrap: wrap;
    overflow: auto;
  }
  .panel-demo p {
    line-height: 1.5;
    margin: 0;
  }
  .panel-demo .p-card .p-card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 1rem 1rem 0 1rem;
  }
  .panel-demo .p-card .p-card-body {
    padding-top: 0;
  }
  .table-demo .p-progressbar {
    height: 0.5rem;
    background-color: #D8DADC;
  }
  .table-demo .p-progressbar .p-progressbar-value {
    background-color: #607D8B;
  }
  .table-demo .p-datatable .p-column-filter {
    display: none;
  }
  .table-demo .table-header {
    display: flex;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    .table-demo .table-header {
      flex-direction: column;
    }
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr {
    border-bottom: 1px solid var(--surface-d);
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-header {
    padding: 1rem;
    text-align: left;
    font-size: 1.5rem;
  }
  .table-demo .p-datatable.p-datatable-customers .p-paginator {
    padding: 1rem;
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    text-align: left;
  }
  .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    cursor: auto;
  }
  .table-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
    /* Responsive */
  }
  .table-demo .p-datatable-customers .p-datatable-tbody > tr > td > .p-column-title {
    display: none;
  }
  .table-demo .customer-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .table-demo .customer-badge.status-qualified {
    background: #C8E6C9;
    color: #256029;
  }
  .table-demo .customer-badge.status-unqualified {
    background: #FFCDD2;
    color: #C63737;
  }
  .table-demo .customer-badge.status-negotiation {
    background: #FEEDAF;
    color: #8A5340;
  }
  .table-demo .customer-badge.status-new {
    background: #B3E5FC;
    color: #23547B;
  }
  .table-demo .customer-badge.status-renewal {
    background: #ECCFFF;
    color: #694382;
  }
  .table-demo .customer-badge.status-proposal {
    background: #FFD8B2;
    color: #805B36;
  }
  .table-demo .customer-badge.status-DELIVERED {
    background: #C8E6C9;
    color: #256029;
  }
  .table-demo .customer-badge.status-CANCELLED {
    background: #FFCDD2;
    color: #C63737;
  }
  .table-demo .customer-badge.status-PENDING {
    background: #FEEDAF;
    color: #8A5340;
  }
  .table-demo .customer-badge.status-RETURNED {
    background: #ECCFFF;
    color: #694382;
  }
  .table-demo .p-progressbar-value.ui-widget-header {
    background: #607d8b;
  }
  @media (max-width: 640px) {
    .table-demo .p-progressbar {
      margin-top: 0.5rem;
    }
  }
  .table-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .table-demo .orders-subtable {
    padding: 1rem;
  }
  .table-demo .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .table-demo .product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
  }
  .table-demo .product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }
  .table-demo .product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
  @media screen and (max-width: 960px) {
    .table-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th, .table-demo .p-datatable.p-datatable-customers .p-datatable-tfoot > tr > td {
      display: none !important;
    }
    .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
    }
    .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
      padding: 0.4rem;
      min-width: 30%;
      display: inline-block;
      margin: -0.4rem 1rem -0.4rem -0.4rem;
      font-weight: bold;
    }
    .table-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td .p-progressbar {
      margin-top: 0.5rem;
    }
    .table-demo .p-datatable.p-datatable-customers colgroup {
      display: none;
    }
    .table-demo .p-datatable .p-datatable-header .table-header {
      flex-direction: column !important;
    }
  }
  .text-demo .demo-container {
    border: 2px solid #dee2e6;
  }
  .spacing-demo .demo-container {
    border: 1px solid #dee2e6;
  }
  .icons-demo .icons-list {
    text-align: center;
    color: #6c757d;
  }
  .icons-demo .icons-list .p-md-2 {
    padding: 1em;
  }
  .icons-demo .icons-list i {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .flexgrid-demo .box {
    background-color: var(--surface-e);
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
  .flexgrid-demo .box-stretched {
    height: 100%;
  }
  .flexgrid-demo .vertical-container {
    margin: 0;
    height: 200px;
    background: var(--surface-d);
    border-radius: 4px;
  }
  .flexgrid-demo .nested-grid .p-col-4 {
    padding-bottom: 1rem;
  }
  .flexgrid-demo .dynamic-box-enter-active, .flexgrid-demo .dynamic-box-leave-active {
    transition: all 0.5s;
  }
  .flexgrid-demo .dynamic-box-enter-from, .flexgrid-demo .dynamic-box-leave-to {
    opacity: 0;
  }
  .flexgrid-demo .dynamic-box-enter-from, .flexgrid-demo .dynamic-box-leave-to {
    transform: translateX(30px);
  }
  .flexgrid-demo p {
    margin: 0;
  }
  .flexbox-demo .p-d-flex > div, .flexbox-demo .box {
    background-color: var(--surface-e);
    text-align: center;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  }
  .flexbox-demo .p-d-flex > div {
    width: 8rem;
  }
  .flexbox-demo pre[class*="language-"] code {
    background: var(--surface-e) !important;
  }
  .elevation-demo .box {
    min-height: 100px;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    margin: 2rem;
    border-radius: 4px;
  }
  .crud-demo .table-header {
    flex-direction: column;
  }
  @media screen and (min-width: 768px) {
    .crud-demo .table-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row !important;
    }
  }
  .crud-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .crud-demo .p-toolbar {
    display: flex;
    flex-wrap: wrap;
  }
  .crud-demo .p-dialog .product-image {
    width: 150px;
    margin: 0 auto 2rem auto;
    display: block;
  }
  .crud-demo .confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .crud-demo .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
  }
  .crud-demo .product-badge.status-instock {
    background: #C8E6C9;
    color: #256029;
  }
  .crud-demo .product-badge.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }
  .crud-demo .product-badge.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
    /* Responsive */
  }
  .crud-demo .datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
  @media screen and (max-width: 960px) {
    .crud-demo .p-datatable.datatable-responsive .p-datatable-thead > tr > th, .crud-demo .p-datatable.datatable-responsive .p-datatable-tfoot > tr > td {
      display: none !important;
    }
    .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr {
      border-bottom: 1px solid var(--surface-d);
    }
    .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td {
      text-align: left;
      display: flex;
      align-items: center;
      border: 0 none !important;
      width: 100% !important;
      float: left;
      clear: left;
      border: 0 none;
    }
    .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
      padding: 0.4rem;
      min-width: 30%;
      display: inline-block;
      margin: -0.4rem 1rem -0.4rem -0.4rem;
      font-weight: bold;
    }
    .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td .p-progressbar {
      margin-top: 0.5rem;
    }
    .crud-demo .p-datatable.datatable-responsive .p-datatable-tbody > tr > td .actions {
      display: flex;
      flex-grow: 1;
      justify-content: center;
    }
  }
  pre[class*="language-"]:before, pre[class*="language-"]:after {
    display: none !important;
  }
  pre[class*="language-"] code {
    border-left: 10px solid var(--surface-d) !important;
    box-shadow: none !important;
    background: var(--surface-b) !important;
    margin: 1em 0;
    color: var(--text-color);
    font-size: 14px;
  }
  pre[class*="language-"] code .token.tag, pre[class*="language-"] code .token.keyword {
    color: #2196F3 !important;
  }
  pre[class*="language-"] code .token.attr-name, pre[class*="language-"] code .token.attr-string {
    color: #2196F3 !important;
  }
  pre[class*="language-"] code .token.attr-value {
    color: #4CAF50 !important;
  }
  pre[class*="language-"] code .token.punctuation {
    color: var(--text-color);
  }
  pre[class*="language-"] code .token.operator, pre[class*="language-"] code .token.string {
    background: transparent;
  }
  .docs i {
    font-family: monaco, Consolas, Lucida Console, monospace;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    padding: 2px;
  }
  .docs li {
    line-height: 1.5;
  }
  .floatlabel-demo .p-field {
    margin-top: 2rem;
    margin-bottom: 0;
  }
  .layout-topbar .notifications .layout-topbar-action-panel img {
    width: 32px;
    height: 32px;
  }
  .layout-topbar .notifications .layout-topbar-action-panel .layout-topbar-action-item {
    border-bottom: 1px solid var(--divider-color);
  }
  .layout-topbar .notifications .layout-topbar-action-panel .layout-topbar-action-item:last-child {
    border-bottom: 0 none;
  }
  .layout-topbar .app .layout-topbar-action-panel button, .layout-topbar .app .layout-topbar-action-panel a {
    padding: 0.5rem 0;
    margin: auto;
  }
  .layout-topbar .app .layout-topbar-action-panel button i,.layout-topbar .app .layout-topbar-action-panel a i {
    width: 42px;
    height: 42px;
    border: 1px solid transparent;
    border-radius: 50%;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layout-topbar .app .layout-topbar-action-panel button span, .layout-topbar .app .layout-topbar-action-panel a span {
    width: 42px;
    height: 42px;
    border: 1px solid transparent;
    border-radius: 50%;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .layout-topbar .layout-topbar-right .layout-topbar-actions-right .layout-topbar-items .layout-topbar-item .layout-topbar-action-panel{
    min-width: 17rem;
  }
  .layout-rightmenu .header {
    padding-bottom: 0.5rem;
    margin-bottom: 0.714rem;
    border-bottom: 1px solid var(--divider-color);
  }
  .layout-rightmenu .online-members img {
    width: 32px;
  }
  .layout-rightmenu .online-members b {
    color: var(--primary-color);
  }
  .layout-rightmenu .latest-activity i {
    border: 1px solid transparent;
    border-radius: 50px;
    background-color: var(--surface-d);
  }
  .layout-rightmenu .next-events ul {
    margin: 0;
    list-style-type: none;
    padding: 0;
  }
  .layout-rightmenu .next-events ul > li {
    padding: 0.875rem 0.5rem;
  }
  .layout-help-page .questions.p-accordion p-accordiontab .p-accordion-tab {
    margin-top: 1rem;
  }
  .layout-help-page .questions.p-accordion p-accordiontab:first-child .p-accordion-tab {
    margin-top: 0;
  }
  .layout-invoice-page .p-invoice-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }
  @media screen and (max-width: 40rem) {
    .layout-invoice-page .p-invoice-datatable-responsive .p-datatable-thead > tr > th, .layout-invoice-page .p-invoice-datatable-responsive .p-datatable-tfoot > tr > td {
      display: none !important;
    }
    .layout-invoice-page .p-invoice-datatable-responsive .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;
    }
    .layout-invoice-page .p-invoice-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
      padding: 0.4rem;
      min-width: 30%;
      display: inline-block;
      margin: -0.4em 1em -0.4em -0.4rem;
      font-weight: bold;
    }
    .layout-invoice-page .p-invoice-datatable-responsive .p-datatable-tbody > tr > td:last-child {
      border-bottom: 1px solid var(--surface-d);
    }
  }
  @media (min-width: 992px) {
    .layout-menu-slim .layout-inline-menu .layout-inline-menu-action {
      justify-content: center;
    }
    .layout-menu-slim .layout-inline-menu .layout-inline-menu-action > span, .layout-menu-slim .layout-inline-menu .layout-inline-menu-action > i {
      display: none !important;
    }
    .layout-menu-slim .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item > button {
      margin: auto;
      justify-content: center;
    }
    .layout-menu-slim .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item > button > i {
      font-size: 1.5rem;
      margin-right: 0 !important;
    }
    .layout-menu-slim .layout-inline-menu .layout-inline-menu-action-panel .layout-inline-menu-action-item > button > span {
      display: none;
    }
  }
  .timeline-demo .custom-marker {
    display: flex;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
  }
  .timeline-demo .p-timeline-event-content, .timeline-demo .p-timeline-event-opposite {
    line-height: 1;
  }
  @media screen and (max-width: 960px) {
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) {
      flex-direction: row !important;
    }
    .timeline-demo .customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
      text-align: left !important;
    }
    .timeline-demo .customized-timeline .p-timeline-event-opposite {
      flex: 0;
    }
    .timeline-demo .customized-timeline .p-card {
      margin-top: 1rem;
    }
  }

  /****************************************************************************************************/
  /*                                       Custom Styles                                              */
  /****************************************************************************************************/
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  
  .topbar-label{color:#FFF; font-weight: bold;margin-left: 5px;}

  .pages-body.accessdenied-page{
    background-color: #4e4d4d !important;
    background: none;
  }

  .p-panel-title{
    width:100%;
  }

  .login-container{
    width: 500px;
  }

  .login-container img{
    width: 50% !important;
    height: auto !important;
  }

  .register-container{
    width: 800px;
  }

  .agent-tooltip{
    width: 500px;
  }
  .p-ink{
    width: 300px !important;
  }
  .estab-link{
    width: 100%;
    height: 100%;
  }
  .estab-line:hover{
    background-color: #ebeeee;
    font-weight: bold;
  }
  .product-grid-img-container{
    padding: 0;
    margin: 0;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-color: #bfc0c0;
  }
  .product-list-img-container{
    padding: 0;
    margin: 0;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    /*margin-bottom: 10px;*/
    border-color: #bfc0c0;
  }
  .product-list-item, .product-list-item:visited{
    cursor: pointer;
  }
  .product-list-item:hover{
    background-color: #ebeeee;
  }

  .dialog-form{
    width:50vw;
  }
  .dialog-form .p-dialog-header{
    background-color: rgb(70, 113, 161);
    color: #ffffff;
  }
  .dialog-form .p-dialog-content{
    padding: 0 10px 10px 10px;
    background-color: rgb(70, 113, 161);
  }
  .action-button{
    /*padding:0 !important;*/
  }
  .confirm-popup{
    position:relative;
    left:50%;
    top:50%;
  }

  .status-circle{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }

  .text-success{color: #4CAF50;}
  .text-warning{color:#f1c811}
  .text-danger{color: #da2a2a;}
  .text-blue{color: #0667b6;}
  .bg-success{background-color:#4CAF50;}
  .bg-danger{background-color: #d14848;}
  .bg-blue{background-color: #0667b6}
  .bg-gray{background-color: #dfe1e2;}
  .bg-warning{background-color: #f5dd0a;}
  .bg-transparent{background-color: transparent;}
  .bg-light{background-color: #dce0e4;}
  
  .blinking{
    -webkit-animation: 1s blink ease infinite;
    -moz-animation: 1s blink ease infinite;
    -ms-animation: 1s blink ease infinite;
    -o-animation: 1s blink ease infinite;
    animation: 1s blink ease infinite;
  }
  @keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-ms-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  @-o-keyframes blink {
    from, to {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }

  .p-message.p-message-warn {
    background: #fff2e2;
    border: solid #cc8925;
    border-top-width: medium;
    border-right-width: medium;
    border-bottom-width: medium;
    border-left-width: medium;
    border-width: 0 0 0 6px;
    color: #cc8925;
  }

  .estab-container{
    border:0;
  }
  .estab-container .p-fieldset-content{
    padding: 0.25rem 0.75rem !important;
  }

  .estab-container .p-fieldset-legend{
    color: #FFF;
    background-color: transparent !important;
  }

  /*--------------------- Jauge Style ----------------------*/
  .gauge {
    position: relative;
    border-radius: 50%/100% 100% 0 0;
    background-color: var(--color, #a22);
    overflow: hidden;
}
.gauge:before{
    content: "";
    display: block;
    padding-top: 50%;   /* ratio of 2:1*/
}
.gauge .chart {
  overflow: hidden;
}
.gauge .mask {
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 0;
  top: 40%;
  background-color: #fff;
  border-radius: 50%/100% 100% 0 0;
}

.gauge .percentage {
    position:  absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    background-color: var(--background, #aaa);
    transform:rotate(var(--rotation)); 
    transform-origin: bottom center; 
    transition-duration: 600;
}
.gauge:hover {
  --rotation: 100deg;
}
.gauge .value {
  position:absolute; bottom:0%; left:0;   
  width:100%; 
  text-align: center;
}

.gauge .min {
  position:absolute; 
  bottom:0; left:5%;   
}
.gauge .max {
  position:absolute; 
  bottom:0; right:5%;   
}

/*------------------------ Other styles ---------------------------*/
.login-links-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.login-button{
  width:100%;
}

.chart-header{
  width: 100%;
  background-color: #1565C0;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}
.chart-header .chart-title{
  color: #FFF;
  margin-bottom: 0;
  font-size: 1.1rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
}

.filter-container .p-panel-content{
  padding: 0 !important;
}

.filter-container-spaced .p-panel-content{
  padding-bottom: 0 !important;
}

.filter-container .p-treenode-content{
  padding: 0 !important;
}

.filter-container .p-field,.filter-container-spaced .p-field{
  margin-bottom: 0;
}

.filter-container .p-field-checkbox{
  margin-bottom: .5rem;
}

.filter-container .p-card-body{
  padding-top: 0;
  padding-bottom: 0;
}

.filter-container .p-card-content{
  padding-bottom: 1px;
}

.dialog-header{
  color:#c00606;
  border-bottom: 1px solid #dddddd;
}

.p-dropdown-label{
  padding:3px 5px;
}

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}